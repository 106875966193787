<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-row>
            <v-col cols="2"
                   v-for="(item, index) in modules"
                   v-bind:key="index"
                   class="viewCard">
                <router-link :to="item.link">
                    <HoverCard :cardData="item"></HoverCard>
                </router-link>
            </v-col>
        </v-row>
    </v-content>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'

    export default {
        name: "Dashboard",
        watch: {},
        props: [
            'moduleName'
        ],
        components: {
            HoverCard
        },
        computed: {
            modules() {
                return [
                    {
                        name: this.$t('registerd_hotel_report'),
                        type: "registered",
                        link: "/reportModules/reportDot/dotHotel/registered",
                        icon: "mdi-chart-areaspline"
                    },
                    {
                        name: this.$t('renewed_hotel_report'),
                        type: "renewed",
                        link: "/reportModules/reportDot/dotHotel/renewed",
                        icon: "mdi-chart-areaspline"
                    },
                ]
            },
            items() {
                return [
                    {
                        text: this.$t('department_of_tourism'),
                        exact: true,
                        to: '/reportModules/reportDot'
                    },
                    {
                        text: this.$t('table.type'),
                        disabled: true,
                        exact: true
                    }
                ]
            }
        },

        mounted() {
        },
        methods: {}
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>